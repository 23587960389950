import H3 from './H3';
import { Link } from "react-router-dom";

import { formatDate } from '../utils/format-dates';
import { slugify } from '../utils/slugify';

export default function Post({ post }) {
  return (
    <div className="py-8">
      <Link
        to={`/posts/${post.id}/${slugify(post.title)}`}
        onClick={() => { window.scroll({ top: 0, left: 0, behavior: "instant" }); }}
      >
        <H3>{post.title}</H3>
      </Link>
      {post.authors &&
        <div className="mt-2">
          <span className="text-s text-zinc-700"></span>
          {post.authors.map((author, index) =>
            <span className="text-s text-zinc-700">
              {index > 0 ? ', ' : ''}
              <a href={author.link} target="_blank" rel="noopener noreferrer">{author.name}</a>
            </span>
          )}
        </div>
      }
      <span className="text-xs text-zinc-500">{formatDate(post.date)}</span>
      <div className="mt-4 post-content" dangerouslySetInnerHTML={{__html:post.content}}></div>
    </div>
  )
}
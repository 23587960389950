import H2 from "../components/H2"
import JoinUsForm from "../components/forms/JoinUsForm"

export default function JoinUs() {
  return (
    <div className="mb-20 lg:mb-32">
      <H2 title="Become a member">
        <p className="mt-2">The Brussels-Africa Hub is an ecosystem of entrepreneurs, corporate executives, investors, advisors and consultants who contribute to Africa's economic development through impactful, sustainable and responsible projects.</p>
        <p className="mt-4">Whether you want to grow your business, explore new markets, find your next investment opportunity or share your expertise, there is a specific <a href="https://files.brusselsafricahub.eu/membership-packages-v202501.pdf" className="text-blue-500" target="_blank" rel="noopener noreferrer">membership package</a> for you.</p>
        <p className="mt-4 mb-4">Please enter your information below to join the Hub.</p>
      </H2>
      <JoinUsForm />
    </div>
  );
}